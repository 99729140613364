import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default function useProjects({ limit: iL, FILTERS }) {
  const { images, site } = useStaticQuery(graphql`
    query servicesImages {
      images: allImageSharp(
        filter: { fluid: { src: { regex: "/portfolio/" } } }
        sort: { order: ASC, fields: fixed___originalName }
      ) {
        edges {
          node {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      site {
        siteMetadata {
          portfolio {
            company
            short
            job
            duration
            darkTheme
            imgDescription
            description
            location
            info {
              key
              value
            }
            work {
              title
              details
            }
          }
        }
      }
    }
  `)
  const projects = site.siteMetadata.portfolio
  projects.forEach(project => {
    project.images = images.edges
      .filter(i => new RegExp(project.short, 'i').test(i.node.fluid.src))
      .map(i => i.node.fluid)
  })

  const [filter, setFilter] = React.useState('all')
  const [limit, setLimit] = React.useState(iL)

  React.useEffect(() => {
    setLimit(iL)
  }, [filter])

  const [groupedProjects] = React.useState(() =>
    projects.reduce(
      (agg, el) => {
        FILTERS.forEach(filter => {
          if (new RegExp(filter, 'i').test(el.job)) {
            agg[filter].push(el)
          }
        })
        agg.all.push(el)
        return agg
      },
      {
        all: [],
        ...FILTERS.reduce((agg, i) => ({ ...agg, [i]: [] }), {}),
      },
    ),
  )

  const getCurrent = () => {
    if (index === null) return { project: null }
    return { project: groupedProjects[filter][index] }
  }

  const [index, set] = React.useState(null)
  const [current, setCurrent] = React.useState(getCurrent)

  const getNextIndex = i => Math.abs(i + 1) % groupedProjects[filter].length
  const getPreviousIndex = i =>
    Math.abs(i + groupedProjects[filter].length - 1) %
    groupedProjects[filter].length

  React.useEffect(() => {
    setCurrent(getCurrent)
  }, [index])

  const next = () => set(getNextIndex(index))
  const previous = () => set(getPreviousIndex(index))
  const close = () => set(null)

  return {
    controls: {
      set,
      next,
      previous,
      close,
      projects: {
        current,
        nextProject: groupedProjects[filter][getNextIndex(index)],
        previousProject: groupedProjects[filter][getPreviousIndex(index)],
      },
    },
    filters: {
      active: filter,
      results: groupedProjects[filter].slice(0, limit),
      toShow: groupedProjects[filter].length - limit,
      showAll: () => setLimit(groupedProjects[filter].length),
      setFilter,
    },
  }
}
